import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import trackCustomEventTrigger from "utils/trackCustomEventTrigger"
import trackingCategoriesHelper from "utils/trackingCategoriesHelper"

import {
  StyledDropdownWrapper,
  StyledDropdownLabel,
  StyledSelectedValue,
  StyledOptionsWrapper,
  StyledOption,
  StyledIcon,
} from "./Dropdown.styles"

const Dropdown = ({
  options,
  onChange,
  label,
  defaultValue,
  direction,
  ...rest
}) => {
  const [areOptionsVisible, setOptionsVisibility] = useState(false)
  const dropdownRef = useRef(null)
  const dropdownWrapperRef = useRef(null)
  const showOptions = () => {
    setOptionsVisibility(true)
  }
  const hideOptions = (event) => {
    if (event && event.target !== dropdownRef.current) {
      setOptionsVisibility(false)
    }
  }

  const toggleOptions = () =>
    areOptionsVisible ? hideOptions() : showOptions()

  useEffect(() => {
    dropdownWrapperRef.current.addEventListener("mouseleave", hideOptions)
    return () => {
      dropdownWrapperRef.current.removeEventListener("mouseleave", hideOptions)
    }
  }, [dropdownWrapperRef])

  const handleSelected = (option) => {
    onChange(option)
    trackCustomEventTrigger({
      category: trackingCategoriesHelper.PORTFOLIO_FILTER,
      action: "Click",
      label: `${trackingCategoriesHelper.PORTFOLIO_FILTER}(${option.label})`,
    })
  }
  return (
    <StyledDropdownWrapper
      {...rest}
      ref={dropdownWrapperRef}
      data-testid="selectMenu"
    >
      <StyledIcon icon="chevronDown" />
      <StyledDropdownLabel>{label}</StyledDropdownLabel>
      <StyledSelectedValue
        ref={dropdownRef}
        onClick={toggleOptions}
        isOpened={areOptionsVisible}
      >
        {defaultValue.label}
      </StyledSelectedValue>
      <StyledOptionsWrapper
        isVisible={areOptionsVisible}
        direction={direction}
        data-testid="selectMenuItems"
      >
        {options.map((option) => (
          <StyledOption
            key={option.value}
            onClick={() => handleSelected(option)}
          >
            {option.label}
          </StyledOption>
        ))}
      </StyledOptionsWrapper>
    </StyledDropdownWrapper>
  )
}
Dropdown.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ).isRequired,
  defaultValue: PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  direction: PropTypes.string,
}

Dropdown.defaultProps = {
  label: null,
  direction: "top",
}
export default Dropdown
