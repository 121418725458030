import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import SEO from "components/molecules/SEO"
import Layout from "components/molecules/Layout"
import PortfolioList from "components/content/Portfolio/PortfolioList"

const PortfolioPage = ({
  pageContext,
  location,
  data: {
    api: { projects },
  },
}) => (
  <Layout language={pageContext.language}>
    <SEO title="Portfolio" />
    <PortfolioList
      projects={projects}
      selectedPropertyType={
        location.state && location.state.selectedPropertyType
      }
      selectedProjectType={location.state && location.state.selectedProjectType}
    />
  </Layout>
)

export default PortfolioPage

PortfolioPage.propTypes = {
  pageContext: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape({
    api: PropTypes.shape({
      projects: PropTypes.arrayOf(
        PropTypes.shape({
          overview: PropTypes.shape({
            status: PropTypes.string,
            city: PropTypes.shape({
              name: PropTypes.string,
              stateCode: PropTypes.string,
            }),
          }),
          primaryPropertyTag: PropTypes.string,
          projectTag: PropTypes.string,
          id: PropTypes.string,
          bannerImages: PropTypes.arrayOf(
            PropTypes.shape({
              image: PropTypes.oneOfType([
                PropTypes.shape({
                  original: PropTypes.string,
                  large: PropTypes.string,
                  medium: PropTypes.string,
                  small: PropTypes.string,
                }),
                PropTypes.string,
              ]),
              id: PropTypes.string,
            })
          ),
          name: PropTypes.string,
          slug: PropTypes.string,
        })
      ),
    }),
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      selectedPropertyType: PropTypes.string,
      selectedProjectType: PropTypes.string,
    }),
  }).isRequired,
}

export const query = graphql`
  query PortfolioPageData($apiLocale: API_Locale) {
    api {
      projects(language: $apiLocale) {
        overview {
          status
          city {
            name
            stateCode
          }
        }
        primaryPropertyTag
        projectTag
        id
        slug
        bannerImages {
          image
          id
        }
        name
      }
    }
  }
`
