import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"

import filterProjects from "utils/filterProjects"
import { Link } from "gatsby-plugin-intl"

import paths from "utils/paths"
import trackCustomEventTrigger from "utils/trackCustomEventTrigger"
import trackingCategoriesHelper from "utils/trackingCategoriesHelper"
import ProjectBlock from "components/molecules/ProjectBlock"
import PortfolioHeader from "components/molecules/PortfolioHeader"
import NoResultsSection from "./NoResultsSection"
import { StyledWrapper, ProjectBlocksWrapper } from "./PortfolioList.styles"

const PortfolioList = ({
  projects,
  selectedPropertyType,
  selectedProjectType,
}) => {
  const [filteredProjects, setFilteredProjects] = useState([])
  const [searchFilters, setSearchFilters] = useState(null)

  const { portfolioMap, portfolioProjectSnapshot } = paths
  const childRef = useRef()
  useEffect(() => {
    if (searchFilters) {
      const projectsToDisplay = filterProjects(searchFilters, projects)

      setFilteredProjects(projectsToDisplay)
    }
  }, [searchFilters])

  const resetDefaultStatuses = () => {
    childRef.current.resetFilters()
  }

  return (
    <StyledWrapper>
      <PortfolioHeader
        count={filteredProjects.length}
        onChange={setSearchFilters}
        icon={{ name: "map", pathToNavigate: portfolioMap }}
        projects={projects.map((project) => ({
          label: project.name,
          value: project.slug,
        }))}
        defaultPropertyType={selectedPropertyType}
        defaultProjectType={selectedProjectType}
        ref={childRef}
      />

      {filteredProjects.length ? (
        <ProjectBlocksWrapper data-testid="portfolioProjectList">
          {filteredProjects.map((project) => (
            <Link
              to={`${portfolioProjectSnapshot(project.slug)}`}
              key={project.id}
              onClick={() =>
                trackCustomEventTrigger({
                  category: trackingCategoriesHelper.PORTFOLIO,
                  action: "Click",
                  label: `${trackingCategoriesHelper.PORTFOLIO} list link(${project.name})`,
                })
              }
            >
              <ProjectBlock project={project} />
            </Link>
          ))}
        </ProjectBlocksWrapper>
      ) : (
        <NoResultsSection onResetClick={resetDefaultStatuses} />
      )}
    </StyledWrapper>
  )
}

PortfolioList.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      overview: PropTypes.shape({
        status: PropTypes.string,
        city: PropTypes.shape({
          name: PropTypes.string,
          stateCode: PropTypes.string,
        }),
      }),
      primaryPropertyTag: PropTypes.string,
      projectTag: PropTypes.string,
      id: PropTypes.string,
      bannerImages: PropTypes.arrayOf(
        PropTypes.shape({
          image: PropTypes.oneOfType([
            PropTypes.shape({
              original: PropTypes.string,
              large: PropTypes.string,
              medium: PropTypes.string,
              small: PropTypes.string,
            }),
            PropTypes.string,
          ]),
          id: PropTypes.string,
        })
      ),
      name: PropTypes.string,
      slug: PropTypes.string,
    })
  ).isRequired,
  selectedPropertyType: PropTypes.string,
  selectedProjectType: PropTypes.string,
}

PortfolioList.defaultProps = {
  selectedPropertyType: null,
  selectedProjectType: null,
}

export default PortfolioList
