import styled, { css } from "styled-components"
import Icon from "components/atoms/Icon"

const textStyles = css`
  font-family: ${({ theme }) => theme.font.family.body};
  font-size: ${({ theme }) => theme.font.size.s};
  line-height: 1.71;
  color: ${({ theme }) => theme.color.secondary01};
  cursor: pointer;
`

const StyledOption = styled.div`
  padding: 0.4rem 0.4rem 0.4rem 1.2rem;

  ${textStyles}

  &:hover {
    background-color: ${({ theme }) => theme.color.secondary05};
  }
`

const StyledDropdownWrapper = styled.div`
  padding-left: 5.1rem;
  position: relative;
  width: fit-content;
`

const StyledSelectedValue = styled.span`
  ${textStyles}
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
  height: 4rem;
  border: solid 1px ${({ theme }) => theme.color.secondary04};
  padding: 0.8rem 4rem 0.8rem 1.2rem;
  cursor: pointer;

  ${({ isOpened }) =>
    isOpened &&
    css`
      color: ${({ theme }) => theme.color.white};
      background: ${({ theme }) => theme.color.main01};
    `}
`
const StyledOptionsWrapper = styled.div`
  position: absolute;
  bottom: 4rem;
  width: calc(100% - 5.1rem);
  z-index: 1000;
  background: ${({ theme }) => theme.color.white};
  border: solid 1px ${({ theme }) => theme.color.secondary04};
  border-bottom: 0;
  max-height: 35rem;
  overflow-y: auto;

  ${({ isVisible }) =>
    css`
      display: ${isVisible ? "block" : "none"};
    `}


    ${({ direction }) =>
      direction === "bottom" &&
      css`
        bottom: initial;
        border-top: 0;
        border-bottom: solid 1px ${({ theme }) => theme.color.secondary04};
      `}


  ${({ theme }) => theme.mq.medium} {
    bottom: initial;
    border-top: 0;
    border-bottom: solid 1px ${({ theme }) => theme.color.secondary04};
  }
`

const StyledDropdownLabel = styled.span`
  position: absolute;
  left: 0;
  top: 1rem;
  font-size: ${({ theme }) => theme.font.size.xs};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  line-height: 1.67;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.color.secondary02};
`

const StyledIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1.2rem;
  pointer-events: none;
`

export {
  StyledDropdownWrapper,
  StyledDropdownLabel,
  StyledSelectedValue,
  StyledOptionsWrapper,
  StyledOption,
  StyledIcon,
}
