import styled from "styled-components"

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: ${({ theme }) => theme.gridWidth};
  padding: 7.2rem ${({ theme }) => theme.layout.mobilePadding} 6.4em;

  ${({ theme }) => theme.mq.medium} {
    padding: 7.2rem ${({ theme }) => theme.layout.mobilePadding} 13.6rem;
  }
`

const ProjectBlocksWrapper = styled.div`
  display: grid;
  grid-gap: 3.2rem;
  grid-template-columns: 1fr;

  ${({ theme }) => theme.mq.medium} {
    grid-template-columns: 1fr 1fr;
  }

  ${({ theme }) => theme.mq.large} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export { StyledWrapper, ProjectBlocksWrapper }
