import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import debounce from "utils/debounce"
import Icon from "components/atoms/Icon"
import { useIntl } from "gatsby-plugin-intl"
import trackCustomEventTrigger from "utils/trackCustomEventTrigger"
import trackingCategoriesHelper from "utils/trackingCategoriesHelper"

import {
  StyledAutocomplete,
  StyledInputLabel,
  StyledInput,
  StyledSuggested,
  StyledOption,
  StyledResultsWrapper,
  StyledInfo,
  StyledResultsLabel,
  StyledIcon,
} from "./Autocomplete.styles"

const Autocomplete = ({
  label,
  sugested,
  options,
  disabled,
  defaultValue,
  onSelected,
  onSearch,
  className,
  placeholder,
  info,
  direction,
}) => {
  const [areOptionsVisible, setOptionsVisibility] = useState(false)
  const [inputValue, setInputValue] = useState(defaultValue)
  const inputRef = useRef(null)
  const autocompleteRef = useRef(null)
  const { formatMessage } = useIntl()

  const showOptions = () => {
    setOptionsVisibility(true)
  }
  const hideOptions = (event) => {
    if (event.target !== inputRef.current) {
      setOptionsVisibility(false)
    }
  }

  const inputDebounce = debounce((value) => {
    if (value.length >= 3) {
      onSearch(value)
    }
  }, 300)

  const inputChangeHandler = (event) => {
    const { value } = event.target
    setInputValue({ label: value, value: null })
    inputDebounce(value)
  }

  const optionSelected = (option) => {
    setOptionsVisibility(false)
    onSelected(option)

    trackCustomEventTrigger({
      category: trackingCategoriesHelper.PORTFOLIO_FILTER,
      action: "Click",
      label: `${trackingCategoriesHelper.PORTFOLIO_FILTER} search input(${option.label})`,
    })
  }

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.addEventListener("focus", showOptions)
      document.addEventListener("click", hideOptions)
    }
    return () => {
      inputRef.current.removeEventListener("focus", showOptions)
      document.removeEventListener("click", hideOptions)
    }
  }, [inputRef])

  useEffect(() => setInputValue(defaultValue), [defaultValue])

  return (
    <StyledAutocomplete
      ref={autocompleteRef}
      isWithLabel={label}
      className={className}
    >
      {label && <StyledInputLabel htmlFor={label}>{label}</StyledInputLabel>}

      <div>
        <StyledInput
          id={label}
          disabled={disabled}
          ref={inputRef}
          value={inputValue.label}
          onChange={inputChangeHandler}
          className={label}
          placeholder={label || placeholder}
        />
        <StyledIcon disabled={disabled} icon="chevronDown" />

        <StyledResultsWrapper
          isVisible={areOptionsVisible}
          direction={direction}
        >
          {sugested && Boolean(sugested.length) && (
            <StyledSuggested>
              <StyledResultsLabel>
                {formatMessage({
                  id: `pages.portfolio.project.location.traffic.suggested`,
                })}
              </StyledResultsLabel>
              {sugested.map((sugestedOption) => (
                <StyledOption
                  title={sugestedOption.label}
                  key={sugestedOption.value}
                  onClick={() => optionSelected(sugestedOption)}
                >
                  {sugestedOption.label}
                </StyledOption>
              ))}
            </StyledSuggested>
          )}
          {!options.length ? (
            <StyledInfo>
              <Icon icon="info" />
              {info ||
                formatMessage({
                  id: `pages.portfolio.project.location.traffic.typeLocation`,
                })}
            </StyledInfo>
          ) : (
            <StyledResultsLabel>
              {formatMessage({
                id: `pages.portfolio.project.location.traffic.searchResults`,
              })}
            </StyledResultsLabel>
          )}
          {options.map((option) => (
            <StyledOption
              key={option.value}
              title={option.label}
              onClick={() => optionSelected(option)}
            >
              {option.label}
            </StyledOption>
          ))}
        </StyledResultsWrapper>
      </div>
    </StyledAutocomplete>
  )
}
Autocomplete.propTypes = {
  label: PropTypes.string,
  sugested: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ),
  disabled: PropTypes.bool,
  defaultValue: PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string,
  }),
  onSelected: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  info: PropTypes.string,
  className: PropTypes.string,
  direction: PropTypes.string,
}

Autocomplete.defaultProps = {
  label: null,
  sugested: [],
  options: [],
  disabled: false,
  defaultValue: { label: "", value: "" },
  placeholder: null,
  info: null,
  className: "",
  direction: "top",
}
export default Autocomplete
