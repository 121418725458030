import React from "react"
import P from "components/atoms/P"
import H from "components/atoms/H"
import ProgressStageAnimation from "components/content/Portfolio/ProjectProgress/ProgressStageAnimation"
import PropTypes from "prop-types"
import { useIntl } from "gatsby-plugin-intl"
import {
  StyledWrapper,
  headingStyles,
  textStyles,
  errorCodeStyles,
  StyledAnimationWrapper,
} from "./NoResultsSection.styles"

const NoResultsSection = ({ onResetClick }) => {
  const { formatMessage } = useIntl()

  return (
    <StyledWrapper>
      <H as="h1" extraStyles={errorCodeStyles}>
        {formatMessage({ id: "pages.portfolio.list.sorry" })}
      </H>
      <H as="h2" extraStyles={headingStyles}>
        {formatMessage({ id: "pages.portfolio.list.noProjects" })}
      </H>
      <P extraStyles={textStyles}>
        <span
          onClick={onResetClick}
          role="button"
          tabIndex={0}
          onKeyDown={onResetClick}
        >
          {formatMessage({ id: "pages.portfolio.list.clickHere" })}
        </span>
        {formatMessage({ id: "pages.portfolio.list.contact" })}
      </P>
      <StyledAnimationWrapper>
        <ProgressStageAnimation animationType="A_OPPORTUNITY_VALIDATION" />
      </StyledAnimationWrapper>
    </StyledWrapper>
  )
}
NoResultsSection.propTypes = {
  onResetClick: PropTypes.func.isRequired,
}
export default NoResultsSection
