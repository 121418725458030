import styled, { css } from "styled-components"
import Icon from "components/atoms/Icon"

const textStyles = css`
  font-family: ${({ theme }) => theme.font.family.body};
  font-size: ${({ theme }) => theme.font.size.s};
  line-height: 1.71;
  color: ${({ theme }) => theme.color.secondary01};
`
const StyledIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1.2rem;
  pointer-events: none;

  ${({ disabled }) =>
    css`
      display: ${disabled ? "none" : "block"};
    `}
`

const StyledInputLabel = styled.label`
  position: absolute;
  left: 0;
  top: 1rem;
  font-size: ${({ theme }) => theme.font.size.xs};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  line-height: 1.67;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.color.secondary02};
`
const StyledInput = styled.input`
  width: 22.1rem;
  height: 4rem;
  border: solid 1px ${({ theme }) => theme.color.secondary04};
  padding: 0.8rem 3rem 0.8rem 1.2rem;
  ${textStyles}

  &::placeholder {
    color: ${({ theme }) => theme.color.secondary01};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.color.secondary05};
  }
`
const StyledSuggested = styled.div`
  border-bottom: solid 1px ${({ theme }) => theme.color.secondary04};
`
const StyledOption = styled.div`
  padding: 0.4rem 1.2rem;
  cursor: pointer;
  /* stylelint-disable */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* stylelint-enable */
  overflow: hidden;
  ${textStyles}

  &:hover {
    background-color: ${({ theme }) => theme.color.secondary05};
  }
`
const StyledResultsWrapper = styled.div`
  bottom: 4rem;
  position: absolute;
  width: 100%;
  z-index: 1000;
  background: ${({ theme }) => theme.color.white};
  border: solid 1px ${({ theme }) => theme.color.secondary04};
  border-bottom: 0;
  max-height: 35rem;
  overflow-y: auto;

  ${({ isVisible }) =>
    css`
      display: ${isVisible ? "block" : "none"};
    `}

  ${({ direction }) =>
    direction === "bottom" &&
    css`
      bottom: initial;
      border-top: 0;
      border-bottom: solid 1px ${({ theme }) => theme.color.secondary04};
    `}

  ${({ theme }) => theme.mq.medium} {
    bottom: initial;
    border-top: 0;
    border-bottom: solid 1px ${({ theme }) => theme.color.secondary04};
  }
`

const StyledAutocomplete = styled.div`
  position: relative;
  margin-bottom: 1.6rem;
  width: fit-content;

  ${({ isWithLabel }) =>
    isWithLabel &&
    css`
      padding-left: 5.1rem;

      ${StyledResultsWrapper} {
        width: calc(100% - 5.1rem);
      }
    `}
`

const StyledInfo = styled.div`
  padding: 0.8rem 1.2rem;
  display: flex;
  align-items: center;
  ${textStyles}

  > svg {
    min-width: 2.4rem;
    margin-right: 0.8rem;
  }
`
const StyledResultsLabel = styled.span`
  display: block;
  font-size: ${({ theme }) => theme.font.size.xs};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  line-height: 1.67;
  letter-spacing: 0.25px;
  padding: 0.4em 0.8rem;
`

export {
  StyledAutocomplete,
  StyledInputLabel,
  StyledInput,
  StyledSuggested,
  StyledOption,
  StyledResultsWrapper,
  StyledInfo,
  StyledResultsLabel,
  StyledIcon,
}
